<template>
  <div class="page">
    <div class="page-header">
      <div class="page-header__name">
        <h1>{{ name }}</h1>
      </div>
      <div v-if="selectSource" class="ml-auto right">
        <select-source-component
          class="mr-2"
          @onChangeSource="changeSource"
        ></select-source-component>
        <!-- <tirscript3-dropdown
          class="source my-2 mr-2"
          placeholder="Источник логов..."
          :items="sourceLogsList"
          @change="onChangeSource"
        >
        </tirscript3-dropdown> -->
        <tirscript3-button
          class="active page-header__bnts"
          @onClick="changeFilter"
        >
          Отфильтровать
        </tirscript3-button>
      </div>
    </div>
    <div class="toolbar">
      <slot name="toolbar"></slot>
    </div>
    <div class="page-content">
      <div class="row">
        <div class="col">
          <tirscript3-loading :loading="isLoading" />
          <slot :sourceLogId="sourceLogId" :filterChange="filterChange"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { reactive, ref, provide } from "vue";
import { Options, Vue } from "vue-class-component";

import DefaultModel from "tirscript3-component-models/src/defaultModel";
import LogsViewFilter from "@/views/components/filter-component/logs-view-filter.vue";

import UiPaginationComponent from "./tirscript3-table/tirscript3-pagination.vue";

import ListViewItem from "tirscript3-list-view/src/ListViewItem";
import api from "@/api";
// import DefaultModelCaps from "@/views/a-model/default-modelCaps";
// import GetProjectLogGroupsResponse from "@dataSource/LoggerMicroService/Services/Models/Response/GetProjectLogGroupsResponse";

@Options<Page>({
  name: "page",
  components: {
    LogsViewFilter,
  },
  props: {
    searchPlaceholder: { default: "Поиск", type: String },
    noApi: { default: false, type: Boolean },
    noFilter: { default: false, type: Boolean },
    defaultSearchField: String,
    noPagination: { default: false, type: Boolean },
    selectSource: { default: true, type: Boolean },
    name: {
      type: String,
      default: "Название страницы",
    },
  },
  watch: {
    filter(value) {
      this.innerItems = this.filter;
    },
  },
  emits: ["onAdd"],
})
export default class Page extends Vue {
  readonly searchPlaceholder: string = "Поиск";
  readonly name: string = "Название страницы";
  readonly noApi: boolean = false;
  readonly noFilter: boolean = false;
  readonly noPagination: boolean = false;

  sourceLogsList: ListViewItem<DefaultModel>[] = [];
  // id источника логов для фильтра
  sourceLogId = 0;
  filterChange = false;

  isLoading = false;
  totalItems = 0;
  currentPage = 1;
  innerItems: any[] = [];
  hasFilter = false;
  countPerPage = 10;
  pagination = true;
  created() {
    this.sourceLogsList = [
      new ListViewItem(new DefaultModel(-1, "Все источники"), true, false),
    ];
  }
  async mounted() {
    await this.getSource();
    var filter = this.sourceLogsList.filter((el) => el.Selected === true)[0];
    const selectedId = filter ? (filter.Value as DefaultModel).id : null;

    this.sourceLogId = selectedId;
  }
  async getSource() {
    let res = [];
    try {
      // res = await this.$api.ProjectLogGroupService.getProjectLogGroupsAsync();
    } catch (error) {
      console.log("error", error);
      return;
    }
    res.forEach((element) => {
      this.sourceLogsList.push(
        new ListViewItem(
          new DefaultModel(
            element.Id,
            `${element.ProjectName}/${element.ServerName}`
          ),
          false,
          false
        )
      );
    });
    let sourceId = localStorage.getItem("source");
    if (sourceId) {
      this.sourceLogsList.forEach(
        (el) =>
          (el.Selected =
            (el.Value as DefaultModel).id == Number.parseInt(sourceId))
      );
    }
  }

  onChangeSource(sourceLogId) {
    this.sourceLogId = sourceLogId;
  }
  onClick() {
    this.$emit("onAdd");
  }
  changeFilter() {
    // this.filterChange = !this.filterChange;
    document.dispatchEvent(
      new CustomEvent("filterChange", {
        detail: { mode: this.filterChange },
      })
    );
    // console.log(`this.filterChange`, this.filterChange);
  }
}
</script>
<style lang="less">
@BaseColor: rgb(34, 26, 58);
.page {
  background: white;
  height: 100%;
  padding-bottom: 20px;
  a {
    text-decoration: none;
  }
  label {
    font-size: 13px;
  }
  h1 {
    font-size: 18px;
    line-height: 22px;
    color: #262838;
    font-weight: 400;
  }
  .right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .page-header {
    display: flex;
    border-bottom: 1px solid @BaseColor;
    padding: 18px 26px;
    font-size: 18px;
    line-height: 22px;
    color: #262838;
    padding-bottom: 12px;
    align-items: center;
    margin-bottom: 10px;
    min-height: 69px;
    flex-wrap: wrap;

    .page-header__bnts {
      &.tirscript3-button {
        height: 36px;
        font-size: 1rem;
      }
    }
    .page-header__name {
      margin-right: 5px;
    }
  }
  .tirscript3-button {
    border-radius: 5px;
    min-width: unset;
  }
  .toolbar {
    padding: 18px 26px;
    display: flex;
  }
  .page-content {
    margin: 30px;
  }
  .page-content__panel {
    margin-bottom: 10px;
    background: #f8f8f8;
    padding: 8px 16px;
  }
  .source.tirscript3-dropdown {
    min-width: 250px;
  }
  .ui-input {
    display: flex;
    input {
      height: 34px;
      background: #fcfcfc;
      font-family: ProximaNova;
      border: 1px solid #d2d2d2;
      box-sizing: border-box;
      padding: 5px 10px 5px 10px;
      font-size: 14px;
      width: 100%;
      border-radius: 5px;
      &::placeholder {
        color: #787878;
      }
      &:focus {
        outline: none;
        border: solid 1px @BaseColor;
      }
    }
    .ui-input__btn {
      background: @BaseColor;
      border-radius: 5px;
      min-width: 27px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      cursor: pointer;
    }
  }
  .ui-textarea {
    background: #fff;
    border: 1px solid #d2d2d2;
    font-size: 14px;
    padding: 10px;
    font-family: ProximaNova;
    resize: none;
    border-radius: 5px;
    &:focus {
      border: 1px solid @BaseColor;
    }
  }
  .tirscript-dropdown {
    font-size: 14px;
    background: #fff;
    border-radius: 5px;
    .ui-select {
      background: white;
      border: 1px solid #d2d2d2;
      &::after {
        content: url("../../../assets/img/pink-arrow.svg");
      }
    }
    .ui-input input:focus &:focus {
      .ui-select {
        border: 1px solid @BaseColor;
      }
      .tirscript3-box-menu {
        border: 1px solid @BaseColor;
      }
    }

    .tirscript3-box-menu {
      background: #fff;
      border: 1px solid #d2d2d2;
      top: 1px;
    }
    .ui-list-view-item.selected {
      background: #e2e2e2;
    }
    .ui-list-view-item:hover {
      background: #e2e2e2;
      color: #000;
      font-weight: 600;
    }
  }
  .pointer {
    cursor: pointer;
  }
  @-webkit-keyframes fadeInOut {
    0% {
      opacity: 0;
      top: -0.5em;
    }
    25% {
      opacity: 1;
      top: 0;
    }
    75% {
      opacity: 0;
      top: 0.5em;
    }
    100% {
      opacity: 0;
      top: 0.5em;
    }
  }
  @keyframes fadeInOut {
    0% {
      opacity: 0;
      top: -0.5em;
    }
    25% {
      opacity: 1;
      top: 0;
    }
    75% {
      opacity: 0;
      top: 0.5em;
    }
    100% {
      opacity: 0;
      top: 0.5em;
    }
  }

  input.tirscript3-checkbox {
    transform: scale(1.3);
    &:checked {
    }
  }
  .ui-date-picker {
    border-radius: 5px;
    input {
      border: 1px solid #d2d2d2;
      border-radius: 5px;
    }
    input,
    input:focus {
      background: #fff;
      color: #414351;
      border-radius: 5px;
    }
    input:focus {
      border: 1px solid @BaseColor;
      border-radius: 5px;
    }
    .icon {
      color: #414351;
    }
    .date-time {
      background: #fff;
      border-radius: 5px;
      color: #414351;
      border: 1px solid @BaseColor;
      transition: all 1s;
      .navigation {
        color: #414351;
        .title_date-picker {
          transition: all 1s;
        }
        .title_date-picker:hover {
          background: @BaseColor;
          color: #fff;
        }
        .backward,
        .next {
          transition: all 1s;
        }
        .backward:hover,
        .next:hover {
          background: @BaseColor;
          color: #fff;
        }
      }
      .minute,
      .hour {
        > div {
          transition: all 1s;
        }
      }
      .minute:hover,
      .hour:hover {
        > div {
          background: @BaseColor;
          color: #fff;
        }
      }
    }
  }
  .icon-apteka-pencil,
  .icon-apteka-delete {
    color: #9e9e9e;
    transition: all 0.5s;
    &:hover {
      color: unset;

      text-shadow: 1px 1px 2px black, 0 0 1em @BaseColor;
    }
  }
  .ui-input {
    .invalid {
      border-color: #dc3545;
    }
  }
  .icon {
    margin-right: 0;
  }
  label {
    line-height: 1.5rem;
  }
  .set-filter {
    display: flex;
    justify-content: center;
    color: orange;
    font-weight: bold;
    font-size: 18px;
  }
  .tirscript-dropdown {
    width: 250px;
    .tirscript-box-menu {
      width: auto;
    }
    .tirscript-box-menu {
      background: #eeeeee;
    }
  }
  .tirscript-button {
    background-color: #630157;
  }
}

.lds-spinner div:after {
  background: #322757;
}

.tirscript-table-header-item {
  background-color: #e2e2e2 !important;
  color: #343545 !important;
}

.tirscript-date-picker .input-group {
  .icon::before {
    color: #fc2586 !important;
  }
}
</style>
