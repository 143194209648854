
import { Options, Vue } from "vue-class-component";
import Page from "@views/page/components/page.vue";

import GetIndicatorsResponse from "@dataSource/LoggerMicroService/Services/Models/Response/GetIndicatorsResponse";
import api from "@/api";
import FilterModel from "../components/filter-model";
@Options({
  components: {
    Page,
  },
})
export default class CurrentIndicatorPage extends Vue {
  filterModel = new FilterModel();
  indicators = [];
  isLoading = false;

  error = {
    isSuccess: true,
    errorMessage: ""
  }

  mounted() {
    this.getCurrentIndicators();
  }

  onclickFilterChange() {
    this.getCurrentIndicators();
  }

  async getCurrentIndicators() {
    this.isLoading = true;
    let res: GetIndicatorsResponse;
    try {
      res = await this.$api.IndicatorsService.getIndicatorsAsync({
        ProjectLogGroupId: this.filterModel.ProjectLogGroupId,
      });
    } catch (error) {
      this.error.isSuccess = false;
      this.error.errorMessage = error;
      this.isLoading = false;
    }
    this.isLoading = false;
    this.indicators = [];
    this.indicators = [
      [
        { Id: 1, Name: "Количество пользователей", Value: res.UsersCount },
        {
          Id: 2,
          Name: "Количество активных пользователей",
          Value: res.ActiveUsersCount,
        },
        {
          Id: 3,
          Name: "Количество Ws-подключений",
          Value: res.WsConnectionsCount,
        },
        {
          Id: 4,
          Name: "Количество активных ws-подключений",
          Value: res.ActiveWsConnectionsCount,
        },
      ],
      [
        {
          Id: 1,
          Name: "Свободное место на главном жестком диске",
          Value: res.MainMemoryDriveFreeSpace,
        },
        { Id: 2, Name: "Нагрузка на процессор", Value: res.CpuUsage },
      ],
      [
        {
          Id: 1,
          Name: "Количество ошибок в методах за час",
          Value: res.MethodsErrorsPerHourCount,
        },
        {
          Id: 2,
          Name: "Количество ошибок в методах за день",
          Value: res.MethodsErrorsPerDayCount,
        },
        {
          Id: 3,
          Name: "Количество ошибок в остальных методах за час",
          Value: res.OtherErrorsPerHourCount,
        },
        {
          Id: 4,
          Name: "Количество ошибок в остальных методах за день",
          Value: res.OtherErrorsPerDayCount,
        },
      ],
    ];
  }
}
