<template>
  <page
    name="Текущие показатели"
    class="current-indicator-page"
    :selectSource="false"
  >
    <template v-slot:toolbar>
      <filter-component
        v-model:filter="filterModel"
        @filterData="onclickFilterChange"
        :selectData="false"
        :selectLogsFilter="false"
        :selectInterval="false"
        :selectViewFilter="false"
      ></filter-component>
    </template>
    <template v-slot:default="slotProps">
      <tirscript3-loading :loading="isLoading"></tirscript3-loading>
      <section v-for="(item, idx) in indicators" :key="idx">
        <bs-row>
          <bs-col v-for="(indicator, idx1) in item" :key="idx1">
            <div class="indicator">
              <div class="indicator__name">{{ indicator.Name }}</div>
              <div class="indicator__value">{{ indicator.Value }}</div>
            </div>
          </bs-col>
        </bs-row>
      </section>
      <bs-row v-if="!this.indicators && !this.indicators.length > 0">
        <bs-col> Задайте источник </bs-col>
      </bs-row>
      <!--Сообщение об ошибке-->
      <div
          v-if="!error.isSuccess"
          class="row mt-2 justify-content-center"
      >
        {{ error.errorMessage }}
      </div>
    </template>
  </page>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Page from "@views/page/components/page.vue";

import GetIndicatorsResponse from "@dataSource/LoggerMicroService/Services/Models/Response/GetIndicatorsResponse";
import api from "@/api";
import FilterModel from "../components/filter-model";
@Options({
  components: {
    Page,
  },
})
export default class CurrentIndicatorPage extends Vue {
  filterModel = new FilterModel();
  indicators = [];
  isLoading = false;

  error = {
    isSuccess: true,
    errorMessage: ""
  }

  mounted() {
    this.getCurrentIndicators();
  }

  onclickFilterChange() {
    this.getCurrentIndicators();
  }

  async getCurrentIndicators() {
    this.isLoading = true;
    let res: GetIndicatorsResponse;
    try {
      res = await this.$api.IndicatorsService.getIndicatorsAsync({
        ProjectLogGroupId: this.filterModel.ProjectLogGroupId,
      });
    } catch (error) {
      this.error.isSuccess = false;
      this.error.errorMessage = error;
      this.isLoading = false;
    }
    this.isLoading = false;
    this.indicators = [];
    this.indicators = [
      [
        { Id: 1, Name: "Количество пользователей", Value: res.UsersCount },
        {
          Id: 2,
          Name: "Количество активных пользователей",
          Value: res.ActiveUsersCount,
        },
        {
          Id: 3,
          Name: "Количество Ws-подключений",
          Value: res.WsConnectionsCount,
        },
        {
          Id: 4,
          Name: "Количество активных ws-подключений",
          Value: res.ActiveWsConnectionsCount,
        },
      ],
      [
        {
          Id: 1,
          Name: "Свободное место на главном жестком диске",
          Value: res.MainMemoryDriveFreeSpace,
        },
        { Id: 2, Name: "Нагрузка на процессор", Value: res.CpuUsage },
      ],
      [
        {
          Id: 1,
          Name: "Количество ошибок в методах за час",
          Value: res.MethodsErrorsPerHourCount,
        },
        {
          Id: 2,
          Name: "Количество ошибок в методах за день",
          Value: res.MethodsErrorsPerDayCount,
        },
        {
          Id: 3,
          Name: "Количество ошибок в остальных методах за час",
          Value: res.OtherErrorsPerHourCount,
        },
        {
          Id: 4,
          Name: "Количество ошибок в остальных методах за день",
          Value: res.OtherErrorsPerDayCount,
        },
      ],
    ];
  }
}
</script>
<style lang="less">
.current-indicator-page {
  section {
    border-bottom: 1px solid #322757;
    > div {
      justify-content: right;
    }
    .indicator {
      min-width: 200px;
      // max-width: 200px;
      // min-height: 100px;
      // width: 100%;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 20px;
      margin: 10px;
      background: #403170;
      color: white;
      cursor: pointer;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      &:hover {
        background: #5b015f;
      }
      .indicator__name {
        display: flex;
        font-size: 14px;
        min-height: 80px;
        // font-weight: bold;
        align-items: center;
        text-align: center;
      }
      .indicator__value {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        background: white;
        color: #000;
        padding: 10px;
      }
    }
  }
}
</style>